export const energyPrices = {
    elec_price: 0.22,
    gas_price: 0.05,
};

export const co2Values = {
    elec_co2: 0.21523,
    gas_co2: 0.20267,
       
};
