// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    display: flex;
    justify-content: space-between;
    margin: 0 15px;
    box-shadow: -16px 32px 11px -34px rgba(0,0,0,0.45);
}

.quote_btn {
    background-color: #ce1226;
    border-radius: 40px;
    text-align: center;
    cursor: pointer;
}

.primary_qt_btn {
    color: #ffffff;
    font-weight: bold;
    border-bottom: none;
    cursor: pointer;
    padding: 0 20px;
    height: 40px;
    line-height: 40px;
}

span.site-logo-img {
    align-self: center;
}

@media only screen and (max-width: 802px) {
    
   #quoteButton {
        display: none;
     }
}`, "",{"version":3,"sources":["webpack://./src/costCalculator/components/header.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,cAAc;IACd,kDAAkD;AACtD;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,mBAAmB;IACnB,eAAe;IACf,eAAe;IACf,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;;GAEG;QACK,aAAa;KAChB;AACL","sourcesContent":[".header {\n    display: flex;\n    justify-content: space-between;\n    margin: 0 15px;\n    box-shadow: -16px 32px 11px -34px rgba(0,0,0,0.45);\n}\n\n.quote_btn {\n    background-color: #ce1226;\n    border-radius: 40px;\n    text-align: center;\n    cursor: pointer;\n}\n\n.primary_qt_btn {\n    color: #ffffff;\n    font-weight: bold;\n    border-bottom: none;\n    cursor: pointer;\n    padding: 0 20px;\n    height: 40px;\n    line-height: 40px;\n}\n\nspan.site-logo-img {\n    align-self: center;\n}\n\n@media only screen and (max-width: 802px) {\n    \n   #quoteButton {\n        display: none;\n     }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
