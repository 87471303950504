// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hero {
    display: flex;
    justify-content: space-between;
    align-items: center;
     margin-bottom: 100px;
    
}

.hero-text {
   
   margin: auto;
 
}

.hero-img {
width: 50%;
    img {
        max-width: 100%;
        
    }
}

.hero-title {
    font-size: 2.2rem;
}

#calculate_button {
    position: relative;
    padding: 10px;
    font-size: 1.3em;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: -30px;
}

@media only screen and (max-width: 802px) {
    .hero {
        flex-direction: column;
        align-items: center;
        margin-bottom: 50px;
    }

    .hero-img {
        width: 100%;
    }

    .hero-subtitle {
        text-align: center;
        margin: 0 20px;
    }

    .hero-img img {
        display: none;
    }
    
   #calculate_button {
    padding: 10px 20px;
   }
}`, "",{"version":3,"sources":["webpack://./src/costCalculator/components/hero.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;KAClB,oBAAoB;;AAEzB;;AAEA;;GAEG,YAAY;;AAEf;;AAEA;AACA,UAAU;IACN;QACI,eAAe;;IAEnB;AACJ;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,gBAAgB;IAChB,iBAAiB;IACjB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI;QACI,sBAAsB;QACtB,mBAAmB;QACnB,mBAAmB;IACvB;;IAEA;QACI,WAAW;IACf;;IAEA;QACI,kBAAkB;QAClB,cAAc;IAClB;;IAEA;QACI,aAAa;IACjB;;GAED;IACC,kBAAkB;GACnB;AACH","sourcesContent":[".hero {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n     margin-bottom: 100px;\n    \n}\n\n.hero-text {\n   \n   margin: auto;\n \n}\n\n.hero-img {\nwidth: 50%;\n    img {\n        max-width: 100%;\n        \n    }\n}\n\n.hero-title {\n    font-size: 2.2rem;\n}\n\n#calculate_button {\n    position: relative;\n    padding: 10px;\n    font-size: 1.3em;\n    font-weight: bold;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    bottom: -30px;\n}\n\n@media only screen and (max-width: 802px) {\n    .hero {\n        flex-direction: column;\n        align-items: center;\n        margin-bottom: 50px;\n    }\n\n    .hero-img {\n        width: 100%;\n    }\n\n    .hero-subtitle {\n        text-align: center;\n        margin: 0 20px;\n    }\n\n    .hero-img img {\n        display: none;\n    }\n    \n   #calculate_button {\n    padding: 10px 20px;\n   }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
